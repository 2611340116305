import React from 'react';
import '../assets/css/animate.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/flaticon.css';
import '../assets/css/magnific-popup.css';
import '../assets/css/responsive.css';
import '../assets/css/style.css';
import logo from '../assets/img/logo.png';
import menuIcon from '../assets/img/menu.png';
import {connect} from 'react-redux';
import {showLoginFormAction, showMenuAction} from '../actions';
import {Link, Redirect} from "react-router-dom";
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Slide from "react-reveal/Slide";
import LightSpeed from "react-reveal/LightSpeed";
class Header extends React.Component{
    constructor(props){
      super(props);
      this.state={
          loggedOn: this.props.state.auth.isLoggedIn,
          goToProfile: false,
          goHome: false
      };
    };

    showLogin=(showHide)=>{
        return this.props.onShowLogin(showHide);
    };
    showMenu=(showHide)=>{
        return this.props.showMenu(showHide);
    };
    goToProfile=()=>{
        this.showMenu(false);
        this.setState({
            goToProfile: true
        });
    };
    goHome=()=>{
        this.showMenu(false);
        this.setState({
            goHome: true
        });
    };
    showBuyShares=(showHide)=>{
        this.showMenu(false);
        this.props.onShowBuyShares(showHide);
    };
    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else if(this.state.goHome){
            return <Redirect to={{pathname: '/', data: {}}}/>
        }else {
            return (
                <header className="main-header">

                    {/*Pop Ups*/}
                    {this.props.state.auth.showLoginForm ||
                    this.props.state.auth.showForgotForm ||
                    this.props.state.auth.showResetForm ||
                    this.props.state.auth.showBySharesForm ||
                    this.props.state.auth.showEditProfileForm ||
                    this.state.showPayment? <div className={`popup-overlay`}>{null}</div> : null}

                    {this.props.state.auth.showBySharesForm ?
                        <div className={`popup-wrapper`}>
                            <div className={`popup`}>
                                <div className={`popup-close`}
                                     onClick={() => this.props.onShowBuyShares(false)}>
                                    <i className={`fa fa-times`}>{null}</i>
                                </div>
                                <Slide bottom>
                                    <div>

                                        <div className={`buy-share-wrapper buy-share-wrapper-pop`}>

                                            <Fade bottom>
                                                <h2>Buy shares</h2>
                                            </Fade>
                                            <div className={`register-container`}>
                                                <Fade bottom>
                                                    <div className={`register-fieldset`}>
                                                        <label className={`small-label`}>Amount to purchase</label>
                                                        <input type="number" value={50000} placeholder={`Enter amount`} />
                                                    </div>
                                                    <div className={`register-fieldset`}>
                                                        <label className={`small-label`}>Amount of shares</label>
                                                        <input type="number" value={1} placeholder={`Enter amount`} />
                                                    </div>
                                                    <div className={`register-fieldset`}>
                                                        <label className={`small-label`}>Mode of Payment</label>
                                                        <div className={`select-container`}>
                                                            <select>
                                                                <option value={`tigopesa`}>Tigopesa</option>
                                                                <option value={`mpesa`}>M-Pesa</option>
                                                                <option value={`airtel`}>Airtel Money</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className={`register-fieldset-last`}>
                                                        <button onClick={()=>this.setState({showPayment: true})} className={`register-button`}>Buy</button>
                                                    </div>
                                                </Fade>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        : null}
                    <div className={`menu-icon`}>
                        <button className={`menu-button`} onClick={() => this.showMenu(true)}>
                            <img src={menuIcon} className="black" alt="Menu"/>
                        </button>
                    </div>
                    <div className={this.props.isProfilePage?`logo-container-profile`:`logo-container`}>
                        <Link to="/">
                            <Flip left>
                                <img src={logo} className="black" alt="App Logo"/>
                            </Flip>
                        </Link>
                    </div>

                    {!this.props.isProfilePage?
                    <div className={`login-nav`}>
                        <button className={`login-button`} onClick={() => this.showLogin(true)}>Login</button>
                        {/*{this.props.state.auth.isLoggedIn?
                        <button className={`login-button`} onClick={() => this.showLogin(true)}>Account</button>
                        :
                        <button className={`login-button`} onClick={() => this.showLogin(true)}>Login</button>
                    }*/}
                    </div>:
                        <div className={`user-profile-nav`}>
                            <button className={`show-profile-button`} onClick={()=>this.props.showEditProfile(true)}>Wilffred Magotti</button>
                        </div>
                    }
                    {this.props.state.auth.showMenu ?
                        <Fade left>
                            <div className={`main-menu`}>
                                <div>
                                    <button className={`menu-close-button`} onClick={() => this.showMenu(false)}>
                                        <i className={`fa fa-times`}>{null}</i>
                                    </button>
                                    <ul>
                                        <li><a href="#" onClick={()=>this.goHome()}>Home</a></li>
                                        <li><a href="#" onClick={()=>this.showBuyShares(true)}>Buy shares</a></li>
                                        <li><Link to={`/`}>Withdraw Revenue</Link></li>
                                        <li><a href='#' onClick={() => this.goToProfile()}>Profile</a></li>
                                        <li><Link to={`/`}>Logout</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade> : null}
                </header>
            );
        }
    }
}

Header.propTypes = {
    isProfilePage: PropTypes.bool
};

const mapStateToProps = (state, ownProps)=>{
    return{
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowLogin: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'login'));
        },
        showEditProfile: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'edit_profile'));
        },
        showMenu: (showHide) => {
            dispatch(showMenuAction(showHide));
        },
        onShowBuyShares: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'buy_shares'));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);