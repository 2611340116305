import React from 'react';
import Header from '../containers/Header';
import {connect} from 'react-redux';
import {Link, Redirect} from "react-router-dom";
import cab from '../assets/img/cab.png';

import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {showLoginFormAction, signInAction} from '../actions';
import LightSpeed from 'react-reveal/LightSpeed';

class HomeScreen extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
        this.state={
            showPayment: false,
            goToProfile: false
        }
    };
    componentDidMount() {
        console.log("Redux Auth Props Are: ", this.props.state.auth);
    }
    showLogin=(showHide)=>{
      return this.props.onShowLogin(showHide);
    };
    showResetForm = (showHide)=>{
        return this.props.onShowResetForm(showHide);
    };
    goToProfile=()=>{
        this.props.onShowLogin(false);
        this.setState({
            goToProfile: true
        });
    };
    render() {
        if(this.state.goToProfile) {
            return <Redirect to={{pathname: '/profile', data: {}}}/>
        }else {
            return (
                <div className="App app-home">
                    <Header isProfilePage={false}/>
                    <div className={`header-banner`}>
                        <div className={`banner-text`}>
                            <Fade bottom>
                                <h5>Buy a share</h5>
                                <h5>in a cab and earn</h5>
                            </Fade>
                            <Zoom><h4>MONTHLY</h4></Zoom>
                        </div>
                        <RubberBand>
                            <div className={`banner-ballon`}>
                                <Fade right>
                                    <img src={cab} className="cab" alt="Cab"/>
                                </Fade>
                                <h5>From<br/>
                                    <span>UGX</span></h5>
                                <h4>200,000</h4>
                                <p>Invest in cab<br/>
                                    and earn<br/>
                                    passive income</p>
                            </div>
                        </RubberBand>
                    </div>

                    <div className={`welcome-text-container`}>
                        <Fade bottom>
                            <h1>ABOUT US</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
                                nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
                                sem.</p>

                            <p>Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum
                                semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                                consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
                                feugiat a,
                            </p>
                        </Fade>
                    </div>

                    <div className={`register-wrapper`}>
                        <Fade bottom>
                            <h1>REGISTER</h1>
                        </Fade>
                        <div className={`register-container`}>
                            <Fade bottom>
                                <div className={`register-fieldset`}>
                                    <label>First name (As shown on passport or ID)</label>
                                    <input type="text" placeholder={`Enter first name`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Last name (As shown on passport or ID)</label>
                                    <input type="text" placeholder={`Enter first name`}/>
                                </div>
                                <h3>Contact details</h3>
                                <div className={`register-fieldset`}>
                                    <label>Email Address</label>
                                    <input type="email" placeholder={`Enter first name`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Contact person</label>
                                    <input type="text" placeholder={`Enter phone or email`}/>
                                </div>
                                <div className={`phone-inputs`}>
                                    <div className={`register-fieldset extension`}>
                                        <label>Code</label>
                                        <input type="text" value={`+255`}/>
                                    </div>
                                    <div className={`register-fieldset phone-number`}>
                                        <label>Phone Number</label>
                                        <input type="text" placeholder={`753443398`}/>
                                    </div>
                                    <div className={`clearfix`}>{null}</div>
                                </div>

                                <div className={`register-fieldset`}>
                                    {/*<label>Password</label>*/}
                                    <input type="password" placeholder={`Enter Password`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    {/*<label>Password</label>*/}
                                    <input type="password" placeholder={`Repeat Password`}/>
                                </div>
                                <div className={`register-fieldset-last`}>
                                    <div className={`checkbox-wrapper`}>
                                        <input type="checkbox"/></div>
                                    <div className={`checkbox-text`}>Would you like to receive an SMS reminder
                                        on the trip.
                                    </div>
                                </div>
                            </Fade>
                            <div className={`register-fieldset-last`}>
                                <button className={`register-button`} onClick={() => this.showLogin(true)}>Continue
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className={`buy-share-wrapper`}>
                        <h1>BUY SHARES</h1>
                        <div className={`buy-share-top`}>
                            <LightSpeed left>
                                <div className={`buy-share-blue-circle`}>
                                    <h4>Buy a share<br/>in a cab and earn</h4>
                                    <h3>Monthly</h3>
                                </div>
                            </LightSpeed>
                            <LightSpeed right>
                                <div className={`buy-share-yellow-circle`}>
                                    <h5>TSHS</h5>
                                    <h4>50,000</h4>
                                    <h3>Per share</h3>
                                    <p>300 shares per car</p>
                                </div>
                            </LightSpeed>

                        </div>

                        <Fade bottom>
                            <h2>Earn up to 7,000 per share</h2>
                        </Fade>
                        <div className={`register-container`}>
                            <Fade bottom>
                                <div className={`register-fieldset`}>
                                    <label>Amount to purchase</label>
                                    <input type="number" value={50000} placeholder={`Enter amount`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Amount of shares</label>
                                    <input type="number" value={1} placeholder={`Enter amount`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Mode of Payment</label>
                                    <div className={`select-container`}>
                                        <select>
                                            <option value={`tigopesa`}>Tigopesa</option>
                                            <option value={`mpesa`}>M-Pesa</option>
                                            <option value={`airtel`}>Airtel Money</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={`register-fieldset-last`}>
                                    <button onClick={() => this.setState({showPayment: true})}
                                            className={`register-button`}>Buy
                                    </button>
                                </div>
                            </Fade>
                        </div>
                    </div>


                    <div className={`register-wrapper`}>
                        <Fade bottom>
                            <h1>CONTACT US</h1>
                        </Fade>
                        <div className={`register-container`}>
                            <Fade bottom>
                                <div className={`register-fieldset`}>
                                    <label>Name</label>
                                    <input type="text" placeholder={`Enter name`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Subject</label>
                                    <input type="text" placeholder={`Enter subject`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Email Address</label>
                                    <input type="email" placeholder={`Enter email`}/>
                                </div>
                                <div className={`register-fieldset`}>
                                    <label>Message</label>
                                    <textarea placeholder={`Enter message`}
                                              className={`contact-message`}>{null}</textarea>
                                </div>

                                <div className={`register-fieldset-last`}>
                                    <button className={`register-button`}>Send</button>
                                </div>

                                <div className={`copy-right`}>
                                    <p>Jamii is a THIG initiative</p>
                                    <p>84 Kinondoni, Acacia Estate<br/>
                                        1st Floor, Regus Offices</p>
                                </div>

                            </Fade>
                        </div>
                    </div>


                    {this.props.state.auth.showLoginForm ?
                        <div className={`popup-wrapper`}>
                            <div className={`popup`}>
                                <div className={`popup-close`}
                                     onClick={() => this.showLogin(false)}>
                                    <i className={`fa fa-times`}>{null}</i>
                                </div>
                                <Slide bottom>
                                    <div>

                                        <Slide bottom>
                                            <h3>Login</h3>
                                            <div className={`register-fieldset`}>
                                                <input type="text" placeholder={`Username`}/>
                                            </div>
                                            <div className={`register-fieldset`}>
                                                <input type="password" placeholder={`Password`}/>
                                            </div>
                                            <p><a onClick={() => this.props.onShowForgotForm(true)}>Forgot password?</a>
                                            </p>
                                            <div className={`register-fieldset-last`}>
                                                <a href="#" onClick={() => this.goToProfile()}
                                                   className={`register-button`}>Login</a>
                                            </div>
                                        </Slide>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        : null}

                    {this.props.state.auth.showForgotForm ?
                        <div className={`popup-wrapper`}>
                            <div className={`popup`}>
                                <div className={`popup-close`}
                                     onClick={() => this.showLogin(false)}>
                                    <i className={`fa fa-times`}>{null}</i>
                                </div>
                                <Slide bottom>
                                    <div>

                                        <Slide bottom>
                                            <h3>Forgotten Password</h3>
                                            <div className={`register-fieldset`}>
                                                <input type="email" placeholder={`Enter email`}/>
                                            </div>

                                            <div className={`register-fieldset-last`}>
                                                <button onClick={() => this.props.onShowResetForm(true)}
                                                        className={`register-button`}>Confirm
                                                </button>
                                            </div>
                                        </Slide>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        : null}

                    {this.props.state.auth.showResetForm ?
                        <div className={`popup-wrapper`}>
                            <div className={`popup`}>
                                <div className={`popup-close`}
                                     onClick={() => this.props.onShowResetForm(false)}>
                                    <i className={`fa fa-times`}>{null}</i>
                                </div>
                                <Slide bottom>
                                    <div>

                                        <Slide bottom>
                                            <h3>Reset Password</h3>
                                            <div className={`register-fieldset`}>
                                                <input type="password" placeholder={`Enter new pssword`}/>
                                            </div>
                                            <div className={`register-fieldset`}>
                                                <input type="password" placeholder={`Repeat new pssword`}/>
                                            </div>

                                            <div className={`register-fieldset-last`}>
                                                <button onClick={() => this.showLogin(true)}
                                                        className={`register-button`}>Confirm
                                                </button>
                                            </div>
                                        </Slide>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        : null}
                    {this.state.showPayment ?
                        <div className={`popup-wrapper`}>
                            <div className={`popup`}>
                                <div className={`popup-close`}
                                     onClick={() => this.props.onShowResetForm(false)}>
                                    <i className={`fa fa-times`}>{null}</i>
                                </div>
                                <Slide bottom>
                                    <div>

                                        <Slide bottom>
                                            <h4>Mobile Money</h4>
                                            <div className={`register-fieldset`}>
                                                <label className={`small-label`}>Total cost</label>
                                                <input type="text" value={`TZS 50,000`}/>
                                            </div>
                                            <div className={`enter-pin-container`}>
                                                <label>Enter pin number</label>
                                                <div className={`pin-inputs`}>
                                                    <input type="text"/>
                                                    <input type="text"/>
                                                    <input type="text"/>
                                                    <input type="text"/>
                                                    <div className={`clearfix`}>{null}</div>
                                                </div>

                                            </div>
                                            <div className={`enter-pin-container`}>
                                                <label className={`small-label`}>Purchase Number: </label>
                                                <p>xyz0-76yt-kl53-xcg1</p>
                                            </div>

                                            <div className={`register-fieldset-last`}>
                                                <button onClick={() => this.setState({showPayment: false})}
                                                        className={`register-button`}>Confirm
                                                </button>
                                            </div>
                                        </Slide>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        : null}
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowLogin: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'login'));
        },
        onShowForgotForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'forgot'));
        },
        onShowResetForm: (showHide) => {
            dispatch(showLoginFormAction(showHide, 'reset'));
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);